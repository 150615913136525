import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import { TWLink } from '../../../../apps/web2/app/components/link';

type Props = {
  children: React.ReactNode | Array<React.ReactNode> | null;
};

type State = {
  hasError: boolean;
};

export default function ErrorBoundary({ children }: Props) {
  const [error, setError] = useState<{
    error: unknown;
    message: string;
    componentStack: string | undefined;
    eventId: string;
  } | null>(null);

  const fallbackNode = (
    <div className="flex justify-center p-6">
      <div className="flex flex-col space-y-6">
        <div className="flex flex-col space-y-6 justify-center">
          <h1 className="flex flex-col items-center text-center typography-heading-xl uppercase mt-12">
            <span
              onClick={() => {
                alert(error?.message + ' ' + error?.error?.stack || 'Unknown error');
              }}
            >
              😢
            </span>
            <span>ERROR</span>
          </h1>
          <div>
            <p className="typography-heading-sm text-center">
              It looks like something went (very) wrong.
            </p>
          </div>
          <div className="flex flex-row justify-center space-x-4">
            <TWLink
              variant="fancy"
              className="border-b-2 border-dashed border-brand-main hover:border-brand-highlight hover:text-brand-highlight"
              to="/home"
            >
              Go Home
            </TWLink>
            <TWLink
              variant="fancy"
              className="border-b-2 border-dashed border-brand-main hover:border-brand-highlight hover:text-brand-highlight"
              to="/random-feature"
            >
              Random feature
            </TWLink>
          </div>
        </div>
      </div>
    </div>
  );

  function handleError(error: unknown, componentStack: string | undefined, eventId: string) {
    console.log('error', error);
    console.log('componentStack', componentStack);
    console.log('eventId', eventId);
    setError({
      error,
      message: error.message,
      componentStack,
      eventId,
    });
  }

  return (
    <Sentry.ErrorBoundary onError={handleError} fallback={fallbackNode}>
      {children}
    </Sentry.ErrorBoundary>
  );
}

function ErrorFallback() {
  return <div>Error</div>;
}

// export default class ErrorBoundary extends React.Component<Props, State> {
//   constructor(props) {
//     super(props);
//     this.state = { hasError: false };
//   }

//   static getDerivedStateFromError(_error) {
//     // Update state so the next render will show the fallback UI.
//     return { hasError: true };
//   }

//   componentDidCatch(error, _errorInfo) {
//     console.log('error', error);
//     console.log('errorInfo', _errorInfo);
//     try {
//       Sentry.captureException(error);
//     } catch (e) {
//       console.error('Error capturing exception', e);
//     }
//     try {
//       const SESSION_STORAGE_KEY = `PI_ERROR_OCCURED_HAS_RECOVERED_${error.message}`;
//       const hasRecoveredAlready = sessionStorage.getItem(SESSION_STORAGE_KEY);
//       if (!hasRecoveredAlready) {
//         sessionStorage.setItem(SESSION_STORAGE_KEY, 'true');
//         setTimeout(() => {
//           window.location.reload();
//         }, 1000);
//       }
//     } catch (e) {
//       console.error('Error setting session storage', e);
//     }
//   }

//   render() {
//     if (this.state.hasError) {
//       return (
//         <div className="flex justify-center p-6">
//           <div className="flex flex-col space-y-6">
//             <div className="flex flex-col space-y-6 justify-center">
//               <h1 className="flex flex-col items-center text-center typography-heading-xl uppercase mt-12">
//                 <span>😢</span>
//                 <span>ERROR</span>
//               </h1>
//               <div>
//                 <p className="typography-heading-sm text-center">
//                   It looks like something went (very) wrong.
//                 </p>
//               </div>
//               <div className="flex flex-row justify-center space-x-4">
//                 <TWLink
//                   variant="fancy"
//                   className="border-b-2 border-dashed border-brand-main hover:border-brand-highlight hover:text-brand-highlight"
//                   to="/home"
//                 >
//                   Go Home
//                 </TWLink>
//                 <TWLink
//                   variant="fancy"
//                   className="border-b-2 border-dashed border-brand-main hover:border-brand-highlight hover:text-brand-highlight"
//                   to="/random-feature"
//                 >
//                   Random feature
//                 </TWLink>
//               </div>
//             </div>
//           </div>
//         </div>
//       );
//     }

//     return <>{this.props.children}</>;
//   }
// }
