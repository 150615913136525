import { useToast } from '@chakra-ui/react';
import { Modal, ModalContent, ModalOverlay, ModalCloseButton } from '../ui/modal';
import React from 'react';
import { ModalNames, useModalState } from '../../misc/wrappers/modal-provider';
import { SubmitHandler, useForm } from 'react-hook-form';
import { signInViaEmailCodeSchema, signUpEmailOnlyStep2Schema } from 'shared/validation/schemas';
import { yupResolver } from '@hookform/resolvers/yup';

import discoveryList from 'shared/misc/discovery';
import { useMutation } from '@apollo/client';
import { SIGN_UP_EMAIL_ONLY_STEP_2 } from 'shared/misc/graphql/SignUpFragments';
import { useAuth } from 'shared/misc/hooks/useAuth';
import useMutationWithAutoToast from 'shared/misc/hooks/useMutationWithAutoToast';
import { SEND_SIGN_IN_EMAIL, SIGN_IN_VIA_CODE } from 'shared/misc/graphql/SignInViewFragments';
import { usePathname, useSearchParams } from 'shared/misc/hooks/routerHooks';
import { useRouter } from '@tanstack/react-router';
import { UserState } from 'shared/__generated__/graphql';
import { TWButton } from '../button';
import PerfInput from '../ui/perf-input';

type Inputs = {
  username: string;
  password: string;
  confirmPassword: string;
  discoverySource: string;
};

export default function EmailSignInModal() {
  const { isOpen, onOpen, onClose } = useModalState(ModalNames.AUTO_SUGGEST_EMAIL_SIGN_IN);
  const finishSignUpModalState = useModalState(ModalNames.FINISH_SIGN_UP);

  const auth = useAuth();

  const toast = useToast();

  const searchParams = useSearchParams();
  const email = searchParams.get('to');

  const pathname = usePathname();

  const [sendSignInEmail, { data: result, loading: sendSignInEmailLoading }] =
    useMutationWithAutoToast(SEND_SIGN_IN_EMAIL, {
      successMessage: 'Email sent.',
    });

  const [verifyEmailAndSignInViaCode, verifyEmailAndSignInViaCodeLoading] =
    useMutation(SIGN_IN_VIA_CODE);

  const signInViaEmailCodeForm = useForm<{ code: string }>({
    resolver: yupResolver(signInViaEmailCodeSchema),
  });

  const code = signInViaEmailCodeForm.watch('code');

  const modalMLR = { base: 0, lg: 16 };
  const modalMTB = { base: 0, lg: 16 };

  const router = useRouter();

  const onCloseAndFixUrl = () => {
    router.navigate({
      to: pathname,
    });
    onClose();
  };

  if (!email || auth.user) {
    return null;
  }

  return (
    <Modal open={isOpen} onOpenChange={(o) => (o ? onOpen() : onCloseAndFixUrl())}>
      <ModalOverlay />
      <ModalContent className="w-full sm:w-[600px] h-[300px] top-[unset] sm:top-0 bottom-0 sm:bottom-[unset]">
        <ModalCloseButton />
        <div className="overflow-y-scroll py-6">
          <div className="space-y-[18px]">
            {!result ? (
              <>
                <h2 className="typography-heading-lg text-brand-highlight">
                  Want to bookmark this?
                </h2>
                <div className="space-y-2">
                  <p className="typography-action-sm leading-[140%]">
                    you can easily sign-in by sending a "magic" link to{' '}
                    <span className="text-lg text-brand-highlight leading-[140%]">{email}</span>
                  </p>
                </div>
              </>
            ) : (
              <>
                <h2 className="typography-heading-lg text-brand-highlight">Done!</h2>
                <div className="space-y-3">
                  <p className="text-lg leading-[140%]">
                    Check your inbox for a magic link and/or a code you can enter here.
                  </p>
                  <PerfInput
                    key="code"
                    placeholder="Enter code"
                    {...signInViaEmailCodeForm.register('code')}
                    error={signInViaEmailCodeForm.formState.errors.code}
                  />
                </div>
              </>
            )}
            <div className="flex items-center justify-end space-x-[3px]">
              <TWButton
                variant="secondary"
                className="opacity-30 hover:opacity-50 transition-opacity"
                onClick={onCloseAndFixUrl}
              >
                Skip
              </TWButton>
              {!result ? (
                <TWButton
                  variant="primary"
                  disabled={sendSignInEmailLoading}
                  className={`${sendSignInEmailLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                  onClick={() =>
                    sendSignInEmail({
                      variables: { email, destination: pathname },
                      fetchPolicy: 'network-only',
                      optimisticResponse: {
                        sendSignInLinkViaEmail: true,
                      },
                    })
                  }
                >
                  {sendSignInEmailLoading ? 'Sending...' : 'Send magic link'}
                </TWButton>
              ) : (
                <TWButton
                  disabled={verifyEmailAndSignInViaCodeLoading.loading}
                  variant="primary"
                  className={`${
                    verifyEmailAndSignInViaCodeLoading.loading
                      ? 'opacity-50 cursor-not-allowed'
                      : ''
                  }`}
                  onClick={async () => {
                    await verifyEmailAndSignInViaCode({
                      variables: { code },
                      onCompleted: async (data) => {
                        const me = await auth.signIn(data.verifyEmailAndSignInViaCode);
                        toast({
                          title: 'Signed in!',
                          status: 'success',
                          duration: 3000,
                          isClosable: true,
                          position: 'top',
                        });
                        onCloseAndFixUrl();
                        if (me.state !== UserState.Complete) {
                          finishSignUpModalState.onOpen();
                        }
                      },
                      onError: (e) => {
                        toast({
                          title: 'An error occured...',
                          status: 'error',
                          duration: 3000,
                          isClosable: true,
                          position: 'top',
                        });
                      },
                    });
                  }}
                >
                  {verifyEmailAndSignInViaCodeLoading.loading ? 'Signing in...' : 'Sign in'}
                </TWButton>
              )}
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}
