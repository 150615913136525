import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { SignedInUserFragment } from '../../__generated__/graphql';
import { useRouterState } from '@tanstack/react-router';
import { sanitizeUser } from '../../analytics';

type TrackPageViewProps = {
  url?: string;
  eventProperties?: any;
};

type AnalyticsContextType = {
  analytics: AnalyticsBrowser | null;
  user: SignedInUserFragment | null;
  trackEvent: (eventType: string, eventValue: string, eventProperties?: any) => Promise<any>;
  trackPageView: (props?: TrackPageViewProps) => Promise<any>;
  identifyUser: (user: SignedInUserFragment, isUserDeleted?: boolean) => void;
};

const AnalyticsContext = createContext<AnalyticsContextType>({
  analytics: null,
  user: null,
  trackEvent: async () => {},
  trackPageView: async () => {},
  identifyUser: () => {},
});

const usePathname = () => {
  const pathname = useRouterState({
    select: (state) => state.location.pathname,
  });

  return pathname;
};

const useSearchParams = () => {
  const searchParams = useRouterState({
    select: (state) => new URLSearchParams(state.location.search),
  });

  return searchParams;
};

export const AnalyticsProvider = ({
  children,
  user,
}: {
  children: React.ReactNode;
  user: SignedInUserFragment | null;
}) => {
  const [analytics, setAnalytics] = useState<AnalyticsBrowser | null>(null);
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const initialPageViewTracked = useRef(false);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development' || typeof window === 'undefined') return;
    const initAnalytics = async () => {
      const a = AnalyticsBrowser.load({ writeKey: 'bOCA2zsLeGKA6CbjkcrPgvRlZ4LZw8LM' });
      setAnalytics(a);
    };
    initAnalytics();
    const url = pathname + searchParams.toString();
    trackPageView({ url });
    initialPageViewTracked.current = true;
  }, []);

  useEffect(() => {
    if (analytics && initialPageViewTracked.current) {
      if (process.env.NODE_ENV === 'development') return;
      const url = pathname + searchParams.toString();
      trackPageView({ url });
    }
  }, [analytics, pathname, searchParams]);

  const identifyUser = (user, isUserDeleted = false) =>
    analytics?.identify(user.id, sanitizeUser(user, isUserDeleted));

  const getContextProperties = () => ({
    url: window.location.href,
    path: pathname,
    referrer: document.referrer,
    search: searchParams.toString(),
    title: document.title,
    userAgent: navigator.userAgent,
    platform: 'web',
    screenHeight: window.screen.height,
    screenWidth: window.screen.width,
    viewportHeight: window.innerHeight,
    viewportWidth: window.innerWidth,
    pixelRatio: window.devicePixelRatio,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    language: navigator.language,
  });

  const trackEvent = async (eventType: string, eventValue: string, eventProperties: any = {}) => {
    if (process.env.NODE_ENV === 'development') return;
    try {
      await analytics?.track(eventType, {
        eventValue: eventValue,
        userId: user?.id,
        url: window.location.href,
        referrer: document.referrer,
        userAgent: navigator.userAgent,
        platform: 'web',
        eventProperties,
        contextProperties: getContextProperties(),
        // covers segment lack of userId without identifying user
        userIdContext: user?.id,
      });
    } catch (error) {
      console.error('Something went wrong tracking the event', error);
    }
    return;
  };

  const trackPageView = async ({
    url = pathname + searchParams.toString(),
    eventProperties,
  }: TrackPageViewProps) => {
    if (process.env.NODE_ENV === 'development') return;
    return analytics?.page({
      eventType: 'page_view',
      eventValue: url,
      userId: user?.id,
      url: url,
      referrer: document.referrer,
      userAgent: navigator.userAgent,
      platform: 'web',
      contextProperties: getContextProperties(),
      eventProperties,
      // covers segment lack of userId without identifying user
      userIdContext: user?.id,
    });
  };

  return (
    <AnalyticsContext.Provider value={{ analytics, user, trackEvent, trackPageView, identifyUser }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => useContext(AnalyticsContext);
