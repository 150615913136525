import { Button } from '../components/button';
import { STEP_PROGRESS_MAP_WEB as STEP_PROGRESS_MAP } from 'shared/data/signup';
import {
  IoArrowForwardCircleOutline,
  IoArrowForwardCircle,
  IoArrowBackCircleOutline,
  IoArrowBackCircle,
} from 'react-icons/io5';
import React, { useEffect, useState } from 'react';
import {
  SignUpNavigationContext,
  SignUpNavigationContextType,
} from 'shared/misc/providers/SignUpNavigationContext';
import { AnimatedTransitionChild } from 'shared/misc/components/AnimatedTransitionChild';
import {
  Popover,
  PopoverBody,
  PopoverHeader,
  Portal,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import ProgressSeparator from 'shared/misc/components/ProgressSeparator';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';
import { ProfileThemeType } from 'shared/__generated__/graphql';
import { USER_SIGN_UP_STEP } from 'shared/data/user';
import { debounce } from 'lodash';
import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/sign-up')({
  component: SignUpComponent,
});

function SignUpComponent() {
  const [progress, setProgress] = useState(0);

  useAutoSetTheme(ProfileThemeType.PiClassic);

  const defaultYPosition = 265;
  const [navigation, setSignupNavigation] = useState<SignUpNavigationContextType>({
    showLeftArrow: false,
    showRightArrow: false,
    onLeftPress: undefined,
    onRightPress: undefined,
    rightArrowDisabled: false,
    leftArrowDisabled: false,
    yPosition: defaultYPosition,
    setNavigation: (nav) => setSignupNavigation((prev) => ({ ...prev, ...nav })),
    onSkip: () => {},
    signupStep: USER_SIGN_UP_STEP.INITIAL,
  });

  useEffect(() => {
    const updateProgress = debounce((step: USER_SIGN_UP_STEP) => {
      const newProgress = (STEP_PROGRESS_MAP[step] / 14) * 100;
      setProgress((prevProgress) => {
        if (prevProgress !== newProgress) {
          return newProgress;
        }
        return prevProgress;
      });
    }, 100);

    if (navigation.signupStep) {
      updateProgress(navigation.signupStep as USER_SIGN_UP_STEP);
    }

    return () => {
      updateProgress.cancel();
    };
  }, [navigation.signupStep]);

  const PopoverElement = navigation.popoverBody ? (
    <PopoverContent>
      <PopoverHeader>{navigation.popoverHeader}</PopoverHeader>
      <PopoverBody>{navigation.popoverBody}</PopoverBody>
    </PopoverContent>
  ) : (
    <></>
  );

  const ButtonStyles = {
    fontSize: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };

  const DisabledStyles = {
    opacity: 0.4,
    cursor: 'not-allowed',
  };

  const LeftArrow = navigation.leftArrowDisabled ? IoArrowBackCircleOutline : IoArrowBackCircle;
  const RightArrow = navigation.rightArrowDisabled
    ? IoArrowForwardCircleOutline
    : IoArrowForwardCircle;

  const progressBarStyle = {
    transition: 'width 0.3s ease-in-out',
    width: `${progress}%`,
  };

  return (
    <div
      style={{ backgroundImage: "url('https://files.pi.fyi/star-background.png')" }}
      className="flex flex-col items-center w-full bg-brand-background bg-cover bg-center box-border min-h-[calc(100dvh-64px)]"
    >
      <div className="relative w-full h-[136px]">
        <div className="absolute top-[40px] left-1/2 transform -translate-x-1/2 logo-signup">
          <div
            className="logo-signup-icon w-[300px] md:w-auto"
            style={{
              width: 350,
              height: 48.5,
              backgroundColor: 'currentColor',
              maskImage: 'url(/static/pi-stencil-black-horizontal.png)',
              maskSize: 'contain',
              maskRepeat: 'no-repeat',
              maskComposite: 'alpha',
              display: 'inline-block',
            }}
          />
        </div>
        {navigation.showSkip && (
          <button
            className="absolute text-gray-400 text-none top-auto bottom-[10px] sm:bottom-auto sm:top-[calc(50%-10px)] transform-translateY-0 sm:transform-translateY-(-50%) right-[12px] sm:right-12"
            onClick={navigation.onSkip}
          >
            Skip
          </button>
        )}
        <div className="absolute bottom-0 left-0 right-0">
          <ProgressSeparator progress={progress} style={progressBarStyle} />
        </div>
      </div>
      <div className="signup-navigation relative h-[calc(100%-136px)] w-full px-[48px] md:px-[38px] py-[40px] sm:py-[100px] md:w-[calc(100%-32px)] max-w-[832px]">
        {navigation.popoverBody ? (
          <>
            <Popover trigger="hover">
              <PopoverTrigger>
                <div
                  onClick={() => {
                    if (navigation.popoverSide === 'left' && !navigation.leftArrowDisabled) {
                      navigation.onLeftPress?.();
                    } else if (
                      (navigation.popoverSide === 'right' || !navigation.popoverSide) &&
                      !navigation.rightArrowDisabled
                    ) {
                      navigation.onRightPress?.();
                    }
                  }}
                  className="absolute cursor-pointer"
                  style={{
                    top: navigation.yPosition ?? defaultYPosition,
                    width: '50px',
                    height: '50px',
                    zIndex: 1,
                    ...(navigation.popoverSide === 'left' ? { left: -16 } : { right: -16 }),
                  }}
                ></div>
              </PopoverTrigger>
              <Portal>{PopoverElement}</Portal>
            </Popover>
          </>
        ) : (
          <></>
        )}
        <AnimatedTransitionChild
          yPosition={navigation.yPosition ?? defaultYPosition}
          style={ButtonStyles}
        >
          <button
            className="signup-navigation-button-left ml-[4px] md:ml-0"
            style={{
              ...(navigation.leftArrowColor ? { color: navigation.leftArrowColor } : {}),
              ...(navigation.leftLabel
                ? {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    fontSize: '16px',
                  }
                : {}),
              ...(navigation.leftArrowDisabled ? DisabledStyles : {}),
              ...(navigation.showLeftArrow ? {} : { visibility: 'hidden', pointerEvents: 'none' }),
            }}
            onClick={navigation.leftArrowDisabled ? undefined : navigation.onLeftPress}
            disabled={navigation.leftArrowDisabled}
          >
            <LeftArrow />
            {navigation.leftLabel}
          </button>
          <button
            className="signup-navigation-button-right mr-[4px] md:mr-0"
            style={{
              ...(navigation.rightArrowColor ? { color: navigation.rightArrowColor } : {}),
              ...(navigation.rightArrowDisabled ? DisabledStyles : {}),
              ...(navigation.rightLabel
                ? {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    fontSize: '16px',
                  }
                : {}),
              ...(navigation.showRightArrow ? {} : { visibility: 'hidden', pointerEvents: 'none' }),
            }}
            onClick={navigation.rightArrowDisabled ? undefined : navigation.onRightPress}
            disabled={navigation.rightArrowDisabled}
          >
            <RightArrow />
            {navigation.rightLabel}
          </button>
        </AnimatedTransitionChild>
        <SignUpNavigationContext.Provider value={navigation}>
          <Outlet />
        </SignUpNavigationContext.Provider>
      </div>
    </div>
  );
}
