import { createFileRoute } from '@tanstack/react-router';

import { Link as TWLink } from 'web2/app/components/link';
import InlineSubscribeToSignUp from 'web2/app/components/inline-subscribe-to-sign-up';
import { preloadImageByKey } from '../../../lib/utils';
import { useAutoSetTheme } from 'shared/misc/providers/ThemeContext';
import { ProfileThemeType } from 'shared/__generated__/graphql';

export const Route = createFileRoute('/welcome/')({
  component: LandingPage,
  // ssr: false,
  loader: async () => {
    try {
      preloadImageByKey('star-background.png');
      preloadImageByKey('app-info-screenshot.png');
      preloadImageByKey('pi-goated-tweet.png');
    } catch (error) {
      console.error(error);
    }
  },
});

function LandingPage() {
  useAutoSetTheme(ProfileThemeType.PiClassic);

  return (
    <>
      <div
        className="flex flex-col h-full w-full items-center justify-center"
        style={{
          backgroundImage: 'url(https://files.pi.fyi/star-background.png)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="mt-6 md:mt-12 m-6 md:m-12 w-full lg:w-[700px]">
          <div className="flex flex-col gap-6 w-full p-6">
            <div className="flex justify-center">
              <img
                src="/static/logo-horizontal.svg"
                width={400}
                height={60}
                alt="Logo"
                style={{ alignSelf: 'center', filter: 'invert(1)' }}
              />
            </div>

            <div className="typography-heading-sm text-center md:typography-heading-md text-brand-highlight">
              The internet is being poisoned by Big Tech and AI. Let's take it back.
            </div>

            <img
              src="https://files.pi.fyi/app-info-screenshot.png"
              width={400}
              height={60}
              alt="Logo"
              className="w-full h-auto border-[1px] border-main border-dashed"
            />

            <InlineSubscribeToSignUp
              ctaText="share what you love on PI.FYI"
              submitText="Sign up"
              confirmationText="Welcome!"
              // redirectTo="/sign-up/more"
              withBorder={false}
              withPadding={false}
              withAutoFocus
              withFinishSignUpModal
              withInputDashedBorder
              onlyInput
            />

            <div className="flex flex-col gap-3">
              <div className="typography-body-sm text-brand-lightgrey text-left">
                By clicking "SIGN UP" you agree to our{' '}
                <TWLink
                  to="/terms"
                  className="text-brand-highlight hover:underline "
                  variant="stylized"
                >
                  Terms
                </TWLink>{' '}
                and{' '}
                <TWLink
                  to="/terms"
                  className="text-brand-highlight hover:underline "
                  variant="stylized"
                >
                  Privacy
                </TWLink>{' '}
                conditions.
              </div>
              <div className="typography-body-sm text-brand-lightgrey text-left">
                Already signed up?{' '}
                <TWLink
                  to="/sign-in"
                  className="text-brand-highlight hover:underline "
                  variant="stylized"
                >
                  Sign in
                </TWLink>
              </div>
              <div className="typography-body-sm text-brand-lightgrey text-left">
                Confused?{' '}
                <TWLink
                  to="/what-is-this"
                  className="text-brand-highlight hover:underline"
                  variant="stylized"
                >
                  Learn more
                </TWLink>
              </div>
            </div>

            <div className="flex justify-center w-full mt-12 mb-4">
              <a href="https://apps.apple.com/us/app/pi-fyi-by-perfectly-imperfect/id6474037926">
                <img
                  src="https://files.pi.fyi/App_Store_Badge.png"
                  alt="star-blue"
                  width={500}
                  height={160}
                  style={{
                    maxWidth: '200px',
                  }}
                />
              </a>
            </div>

            <img
              src="https://files.pi.fyi/pi-goated-tweet.png"
              alt="star-blue"
              width={700}
              height={419}
              style={{
                aspectRatio: '700/419',
                width: '100%',
                height: 'auto',
              }}
            />

            {/* <Text textStyle="brand.headingMd" color="brand.highlight" textAlign="center">
              Thousands of people are sharing what they love on PI.FYI.
            </Text>
            <Text textStyle="brand.headingMd" color="brand.highlight" textAlign="center">
              Why aren't you?
            </Text> */}
          </div>
        </div>
      </div>
    </>
  );
}
