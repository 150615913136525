'use client';
import React, { useRef } from 'react';
import { Box, Stack, Spinner, SimpleGrid, Text } from '@chakra-ui/react';
import { redirect } from '@tanstack/react-router';
import _ from 'lodash';
import { FeedItemType } from '../../../../__generated__/graphql';
import SkeletonFeedItem from '../../SkeletonFeedItem';
import FeedItem from './FeedItem';
import useGenericFeedInfiniteScroll, {
  useGenericFeedInfiniteScrollArgs,
} from './utils/useGenericFeedInfiniteScroll';
import { WindowVirtualizer, type WindowVirtualizerHandle } from 'virtua';

interface GenericFeedInfiniteScrollViewProps extends useGenericFeedInfiniteScrollArgs {
  hideAllFeedContext?: boolean;
  hideContextForTypes?: Set<string | FeedItemType>;
}

export default function GenericFeedInfiniteScrollView(props: GenericFeedInfiniteScrollViewProps) {
  const { types, filter, hideAllFeedContext = false, hideContextForTypes = new Set() } = props;

  if (!types && !filter) {
    throw new Error('Must supply either types or filter.');
  }

  // Remove InView: We no longer track in-view changes, so always pass true.
  const isInView = true;

  const { data, loading, feedItems, getNextPage, error, networkStatus, hasNextPage } =
    useGenericFeedInfiniteScroll({
      isInView,
      ...props,
    });

  const virtuaRef = useRef<WindowVirtualizerHandle>(null);

  if ((!data && !loading) || error) {
    return redirect({ to: '/not-found' });
  }

  return (
    <>
      <div className="mb-[500px] p-0 md:p-[24px] pt-0">
        {!data && loading && (
          <>
            <SkeletonFeedItem />
            <SkeletonFeedItem />
            <SkeletonFeedItem />
            <SkeletonFeedItem />
            <SkeletonFeedItem />
            <SkeletonFeedItem />
          </>
        )}
        {feedItems.length > 0 ? (
          <>
            <WindowVirtualizer
              ref={virtuaRef}
              className="h-full w-full"
              itemSize={200}
              overscan={5}
              onScroll={async () => {
                if (!virtuaRef.current) return;
                if (loading) return;
                if (hasNextPage && virtuaRef.current.findEndIndex() + 5 > feedItems.length) {
                  await getNextPage();
                  console.log('getting next page');
                }
              }}
            >
              {feedItems.map((feedItem) => {
                const feedItemNode = (
                  <FeedItem
                    feedItem={feedItem}
                    hideContextForTypes={hideContextForTypes}
                    hideAllFeedContext={hideAllFeedContext}
                  />
                );
                return feedItemNode ? (
                  <div className="flex justify-center w-full h-full py-4" key={feedItem.id}>
                    {feedItemNode}
                  </div>
                ) : null;
              })}
              {loading && (
                <div className="flex justify-center py-4">
                  <Spinner size="lg" />
                </div>
              )}
            </WindowVirtualizer>
          </>
        ) : (
          <div className="typography-heading-sm text-center">Nothing to see here.</div>
        )}
      </div>
    </>
  );
}
