'use client';
import { NetworkStatus, useQuery } from '@apollo/client';
import _ from 'lodash';
import { gql } from '../../../../../__generated__';
import { FeedItemType, RecCategory } from '../../../../../__generated__/graphql';
import { MergedFeedItemType, combineRelatedFeedItems } from './feedUtils';
import { getFeedItemData } from './getFeedItemData';

const GenericFeedScrollViewQuery = gql(/* GraphQL */ `
  query getGenericFeedScrollView(
    $first: Int
    $after: String
    $originUserId: String
    $category: RecCategory
    $showGlobalFeed: Boolean
    $isRandomSelection: Boolean
    $types: [FeedItemType!]
  ) {
    fullFeedConnection: feedConnection(
      first: $first
      after: $after
      category: $category
      originUserId: $originUserId
      showGlobalFeed: $showGlobalFeed
      isRandomSelection: $isRandomSelection
      types: $types
      excludeSessionUser: true
    ) @connection(key: "FullFeedView") {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...FullFeedItem
        }
      }
    }
  }
`);

const PAGE_LENGTH = 10;

export enum FeedUserFilter {
  FOLLOWING = 'FOLLOWING',
  EVERYONE = 'EVERYONE',
}

export enum FeedContentFilter {
  ALL = 'ALL',
  ONLY_RECS = 'ONLY_RECS',
}

export interface useGenericFeedInfiniteScrollArgs {
  onlyFollowing?: boolean;
  showGlobalFeed?: boolean;
  isInView?: boolean;
  filter?: FeedContentFilter;
  types: FeedItemType[];
  category?: RecCategory;
  combineFeedItems?: boolean;
  originUserId?: string;
  pageLength?: number;
  isRandomSelection?: boolean;
}

export default function useGenericFeedInfiniteScroll({
  isInView,
  types,
  filter,
  category,
  originUserId,
  onlyFollowing = false,
  showGlobalFeed = true,
  combineFeedItems = false,
  isRandomSelection,
  pageLength = PAGE_LENGTH,
}: useGenericFeedInfiniteScrollArgs) {
  if (!types && !filter) {
    throw new Error('Must supply either types of filter.');
  }

  const { data, loading, refetch, error, fetchMore, networkStatus, variables } = useQuery(
    GenericFeedScrollViewQuery,
    {
      fetchPolicy: 'cache-and-network',
      // pollInterval: isInView ? 60000 * 2 : undefined,
      variables: {
        category,
        first: pageLength,
        showGlobalFeed: onlyFollowing ? false : showGlobalFeed,
        types,
        originUserId,
        isRandomSelection,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const getNextPage = async () => {
    if (!data?.fullFeedConnection.pageInfo.hasNextPage && !loading) return;
    await fetchMore({
      variables: {
        ...variables,
        first: pageLength,
        after: data?.fullFeedConnection.pageInfo.endCursor,
      },
    });
  };

  const feedItems = combineFeedItems
    ? combineRelatedFeedItems(
        (data?.fullFeedConnection?.edges || []).map(({ node }) => node),
        new Set([
          MergedFeedItemType.MERGED_NEW_USER_FOLLOWS,
          MergedFeedItemType.MERGED_NEW_REC_ENDORSE,
          // MergedFeedItemType.MERGED_RE_REC,
          MergedFeedItemType.MERGED_COMMENT,
          MergedFeedItemType.MERGED_PROMPT_REPLY,
        ]),
      ).filter((node) => getFeedItemData({ feedItem: node }))
    : data?.fullFeedConnection?.edges
        .filter(({ node }) => getFeedItemData({ feedItem: node }))
        .map(({ node }) => node) || [];

  const isRefetching = networkStatus === NetworkStatus.refetch || (!data && loading);

  return {
    data,
    loading,
    feedItems,
    getNextPage,
    isRefetching,
    refetch,
    error,
    hasNextPage: data?.fullFeedConnection.pageInfo.hasNextPage,
  };
}
