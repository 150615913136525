'use client';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Portal } from '@chakra-ui/react';

import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client';
import { AuthPopoverOptions } from 'shared/misc/components/AuthPopoverOptions';
import { ProfileThemeType, SignedInUserFragment } from 'shared/__generated__/graphql';
import { createOrGetExistingClient, persistCacheAsync } from 'shared/misc/apollo/client';
import HeapAnalytics from 'shared/misc/components/HeapAnalytics';
import { ThemeProvider } from 'shared/misc/providers/ThemeContext';
import ChakraProviderWithTheme from 'shared/misc/providers/ChakraProviderWithTheme';
import { PaywallProvider } from 'shared/misc/components/gates/BlurredPaywall';
import { Auth } from 'shared/misc/wrappers/Auth';
import { AnalyticsProvider } from 'shared/misc/providers/AnalyticsContext';
import WithSidebar from './WithSidebar';
import ErrorBoundary from 'shared/misc/wrappers/ErrorBoundary';
import HandleWebView from 'shared/misc/wrappers/HandleWebView';
import { useRouterState } from '@tanstack/react-router';
import { TooltipProvider } from 'web2/app/components/ui/tooltip';
import GlobalModals from '../../components/modals/global-modals';

const usePathname = () => {
  const pathname = useRouterState({
    select: (state) => state.location.pathname,
  });

  return pathname;
};

type RootProviderProps = {
  children: React.ReactNode | React.ReactNode[] | React.ElementType;
  modal?: React.ReactNode;
  sessionUser?: SignedInUserFragment | null;
  theme?: ProfileThemeType | null;
  cookie?: string;
};

export function RootProviders({
  children,
  modal = null,
  sessionUser,
  cookie,
  theme,
}: RootProviderProps) {
  const pathname = usePathname();
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject> | null>(null);

  useEffect(() => {
    (async () => {
      if (typeof window === 'undefined') {
        return;
      }
      // const client = await persistCacheAsync();
      // setClient(client);
    })();
  }, []);

  let t = theme;

  const isPiClassic = useMemo(() => {
    return ['/welcome', '/sign-up', '/sign-in', '/read', '/p', '/careers', '/what-is-this'].some(
      (pattern) => pathname.startsWith(pattern),
    );
  }, [pathname]);

  if (isPiClassic) {
    t = ProfileThemeType.PiClassic;
  }

  const makeClient = useCallback(() => {
    const client = createOrGetExistingClient();
    return client;
  }, [cookie]);

  return (
    <TooltipProvider delayDuration={0}>
      <AnalyticsProvider user={sessionUser as SignedInUserFragment}>
        <Auth sessionUser={sessionUser as SignedInUserFragment}>
          <ThemeProvider theme={t as ProfileThemeType}>
            <ChakraProviderWithTheme>
              <ErrorBoundary>
                <HeapAnalytics>
                  <WithSidebar>
                    {modal && <Portal>{modal}</Portal>}
                    {children}
                  </WithSidebar>
                </HeapAnalytics>
              </ErrorBoundary>
            </ChakraProviderWithTheme>
            <GlobalModals />
          </ThemeProvider>
        </Auth>
      </AnalyticsProvider>
    </TooltipProvider>
  );
}
