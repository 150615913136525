import React, { ReactNode } from 'react';
import { FieldError } from 'react-hook-form';
import { cn } from '../../utils/cn';

interface PerfInputProps {
  error?: FieldError | undefined;
  label?: string;
  placeholder?: string;
  errorColor?: string;
  leftNode?: ReactNode;
  rightNode?: ReactNode;
  beforeNode?: ReactNode;
  afterNode?: ReactNode;
  className?: string;
  autofocus?: boolean;
}

function PerfInput(
  {
    error,
    label,
    errorColor,
    placeholder,
    leftNode = null,
    rightNode = null,
    beforeNode,
    afterNode,
    className,
    autofocus,
    ...inputProps
  }: PerfInputProps,
  ref: React.Ref<HTMLInputElement>,
) {
  const isError = !!error;

  return (
    <form className={cn(`${isError ? 'border-red-500' : ''}`, className)}>
      {beforeNode}
      {label && (
        <label className="typography-action-sm block mb-[6px] uppercase font-bold">{label}</label>
      )}
      <div className="relative">
        {leftNode && (
          <div className="absolute inset-y-0 left-0 flex items-center pl-3">{leftNode}</div>
        )}
        <input
          autoFocus={autofocus}
          className={cn(
            'w-full h-10 pl-4 pr-4 rounded-none min-w-0 outline outline-2 outline-transparent outline-offset-2 relative appearance-none transition font-normal border border-current border-brand-main leading-none tracking-[1%] bg-inherit hover:border-brand-highlight focus:border-brand-highlight mb-1.5 font-normal typography-body-md text-[16px]',
            error && 'border-red-500 border-[2px]',
          )}
          placeholder={placeholder}
          {...inputProps}
          ref={ref}
        />
        {rightNode && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">{rightNode}</div>
        )}
      </div>
      {error && (
        <p className="typography-action-sm text-[12px] uppercase text-red-500 ">{error?.message}</p>
      )}
      {afterNode}
    </form>
  );
}

export default React.forwardRef(PerfInput);
