import React, { createContext, useContext, useEffect, useState } from 'react';

type Breakpoint = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

// Module-scoped breakpoint manager
export class BreakpointManager {
  private current: Breakpoint = '2xl';
  private subscribers = new Set<(bp: Breakpoint) => void>();
  private mediaQueries: Record<Breakpoint, MediaQueryList>;

  constructor() {
    try {
      this.mediaQueries = {
        '2xl': window.matchMedia?.('(min-width: 1536px)'),
        xl: window.matchMedia?.('(min-width: 1280px)'),
        lg: window.matchMedia?.('(min-width: 1024px)'),
        md: window.matchMedia?.('(min-width: 768px)'),
        sm: window.matchMedia?.('(min-width: 640px)'),
      };

      this.handleResize = this.handleResize.bind(this);
      this.init();
    } catch (error) {
      console.error(error);
    }
  }

  private init() {
    try {
      this.mediaQueries['2xl']?.addListener(this.handleResize);
      this.mediaQueries.xl?.addListener(this.handleResize);
      this.mediaQueries.lg?.addListener(this.handleResize);
      this.mediaQueries.md?.addListener(this.handleResize);
      this.mediaQueries.sm?.addListener(this.handleResize);
      this.handleResize();
    } catch (error) {
      console.error(error);
    }
  }

  private handleResize() {
    try {
      let newBreakpoint: Breakpoint = 'sm';
      if (this.mediaQueries['2xl']?.matches) {
        newBreakpoint = '2xl';
      } else if (this.mediaQueries.xl?.matches) {
        newBreakpoint = 'xl';
      } else if (this.mediaQueries.lg?.matches) {
        newBreakpoint = 'lg';
      } else if (this.mediaQueries.md?.matches) {
        newBreakpoint = 'md';
      }

      if (newBreakpoint !== this.current) {
        this.current = newBreakpoint;
        this.subscribers.forEach((cb) => cb(this.current));
      }
    } catch (error) {
      console.error(error);
    }
  }

  subscribe(callback: (bp: Breakpoint) => void) {
    this.subscribers.add(callback);
    return () => this.subscribers.delete(callback);
  }

  getCurrent() {
    return this.current;
  }
}

export let breakpointManager: BreakpointManager | null = null;
if (typeof window !== 'undefined') {
  // Singleton instance
  breakpointManager = new BreakpointManager();
}
// Modified provider (only provides initial value)
const BreakpointContext = createContext<Breakpoint>('lg');

export function BreakpointProvider({
  children,
  initialBreakpoint,
}: {
  children: React.ReactNode;
  initialBreakpoint: Breakpoint;
}) {
  return (
    <BreakpointContext.Provider value={initialBreakpoint}>{children}</BreakpointContext.Provider>
  );
}

// Modified hook with internal state
export function useBreakpoint() {
  const initialBreakpoint = useContext(BreakpointContext);
  const [breakpoint, setBreakpoint] = useState<Breakpoint>(initialBreakpoint);

  useEffect(() => {
    if (!breakpointManager) {
      breakpointManager = new BreakpointManager();
    }
    // Sync with current breakpoint in case it changed since initial render
    setBreakpoint(breakpointManager.getCurrent());

    const unsubscribe = breakpointManager.subscribe((newBreakpoint) => {
      setBreakpoint(newBreakpoint);
    });

    return unsubscribe;
  }, []);

  return breakpoint;
}
