import React, { ReactNode } from 'react';
import { Box, HStack, Stack, Text, Tooltip } from '@chakra-ui/react';
import NextImage from 'next/image';
import formatRelative from 'date-fns/formatRelative';
import { Link } from '@tanstack/react-router';
import { FeedItemType, FullFeedItemFragment } from '../../../../__generated__/graphql';
import { gql } from '../../../../__generated__/gql';
import { USER_SIGN_UP_STEP } from '../../../../data/user';
import RecommendationItem, { RecommendationItemProps } from '../recommendations/RecommendationItem';
import PromptItem, { PromptItemProps } from '../prompts/PromptItem';
import { getRelativeDateString } from '../../../../dates';
import { useAuth } from '../../../hooks/useAuth';
import PerfImageFromFile from '../../util/PerfImageFromFile';
import { useIsBackgroundLight } from '../../../hooks/useContrastHighlight';
import { FeedItemDisplayData, useFeedItemData } from './utils/useFeedItemData';
import { MergedFeedItem, MergedFeedItemType } from './utils/feedUtils';

gql(/* GraphQL */ `
  fragment FullFeedItem on FeedItem {
    id
    type
    createdAt
    extra
    targetCommentId
    originUser {
      ...UserCore
      isActive
    }
    targetUser {
      ...UserCore
      isActive
    }
    targetRec {
      feature {
        id
        list {
          id
        }
      }
      ...RecommendationItem
      isHidden
    }
    targetFeature {
      id
      title
      url
      thumbnailFile {
        file {
          url
          ...PerfImageFromFile
        }
      }
      list {
        id
        intro
      }
    }
    targetArticle {
      ...ArticleTile
    }
    targetList {
      id
      title
      user {
        id
        username
      }
    }
    targetComment {
      ...CommentItem
    }
    targetPrompt {
      ...PromptItem
      isHidden
    }
  }
`);

interface FeedItemProps {
  feedItem: MergedFeedItem<FullFeedItemFragment> | FullFeedItemFragment;
  markSeen?: boolean;
  fullWidth?: boolean;
  showHasSeenDot?: boolean;
  forceGeneric?: boolean;
  relateRecToUser?: boolean;
  hideTypes?: Set<string | FeedItemType>;
  hideContextForTypes?: Set<string | FeedItemType>;
  hideContext?: boolean;
  hideAllFeedContext?: boolean;
  recProps?: Omit<RecommendationItemProps, 'rec'>;
  promptProps?: Omit<PromptItemProps, 'prompt'>;
}

export const VerboseTypeMap = {
  [FeedItemType.NewUserFollow]: true,
  [FeedItemType.NewComment]: true,
};

// type FeedNode;

export default function FeedItem({
  feedItem,
  fullWidth,
  markSeen = false,
  relateRecToUser = false,
  forceGeneric = false,
  hideTypes = new Set([]),
  hideContextForTypes = new Set([]),
  hideAllFeedContext = false,
  recProps,
  promptProps,
}: FeedItemProps) {
  const auth = useAuth();

  const isInline = recProps?.isInline || promptProps?.isInline;

  const noOfLines = !!isInline ? 1 : 3;

  const { originUser, targetRec, targetUser, targetList, targetPrompt } = feedItem;

  const displayData = useFeedItemData({
    feedItem,
    hideTypes,
    isGeneric: !relateRecToUser && !VerboseTypeMap[feedItem.type],
    referenceUserId: auth?.user?.id,
  });

  let contextNode: ReactNode | null = null;
  let mainNode: ReactNode | null = null;

  const isBackgroundLight = useIsBackgroundLight();

  switch (feedItem.type) {
    case FeedItemType.NewRePost:
    case FeedItemType.NewRePostWithContent:
    case FeedItemType.AddRecommendation: {
      if (!targetRec || !originUser) return null;

      // contextNode = (
      //   <HStack
      //     spacing="3px"
      //     alignItems="center"
      //     alignSelf="center"
      //     width="fit-content"
      //     opacity={isBackgroundLight ? 0.3 : 0.8}
      //     _hover={{ opacity: 1 }}
      //   >
      //     <Text
      //       textStyle="brand.headingSm"
      //       as={Link}
      //       href={`/rec/${targetRec.id}`}
      //       variant="stylizedNoDefaultBorder"
      //     >
      //       {targetRec.repostTargetRec ? (
      //         <em>@{originUser.username} RE-REC'D</em>
      //       ) : (
      //         <em>
      //           NEW {targetRec.repostTargetRec ? targetRec.repostTargetRec.emoji : targetRec.emoji}{' '}
      //           REC FROM @{originUser.username}
      //         </em>
      //       )}
      //     </Text>
      //   </HStack>
      // );

      mainNode = (
        <RecommendationItem
          rec={targetRec}
          showPrompt
          withLessPadding
          withMinHeight={false}
          {...recProps}
        />
      );

      break;
    }

    case FeedItemType.NewFeature: {
      return null;
      // imageSrc =  targetFeature.thumbnailFile?.file?.url ||
      // `/default-profile-photo-${logoColor}-small.png`
      // mainLink = targetFeature.url
      // mainContent = targetFeature.title
      break;
    }

    case FeedItemType.NewBookmark: {
      if (!targetRec) return null;

      break;
    }

    case FeedItemType.NewRecEndorse: {
      if (!originUser || !targetRec) return null;

      contextNode = (
        <HStack
          spacing="3px"
          alignItems="center"
          width="fit-content"
          opacity={isBackgroundLight ? 0.3 : 0.8}
          _hover={{ opacity: 1 }}
        >
          <Link
            to={`/u/${originUser.username}`}
            className={'typography-heading-sm text-theme-main hover:opacity-80'}
          >
            <em>@{originUser.username} LIKED THIS</em>
          </Link>
          {/* <Box>
            <NextImage
              alt="endorsements"
              src={`https://files.pi.fyi/one-endorsed-user-endorsed.png`}
              width={15}
              height={15}
            />
          </Box> */}
        </HStack>
      );

      mainNode = (
        <RecommendationItem
          rec={targetRec}
          showPrompt
          withLessPadding
          withMinHeight={false}
          nooflines={noOfLines}
          // withEmojiSameLine={!!targetRec.prompt}
          // withUrlSameLine={!!targetRec.prompt}
          // contextNode={contextNode}
          {...recProps}
        />
      );

      break;
    }

    case FeedItemType.NewUserFollow: {
      if (!originUser || !targetUser) return null;

      break;
    }

    case FeedItemType.CreateList: {
      if (targetList == null) return null;

      break;
    }

    case FeedItemType.NewUser: {
      if (
        originUser == null ||
        (originUser?.signUpStep !== null && originUser?.signUpStep !== USER_SIGN_UP_STEP.DONE)
      )
        return null;

      break;
    }

    case FeedItemType.NewComment: {
      if (originUser == null || targetRec == null) return null;

      break;
    }

    case FeedItemType.NewCommentEndorse: {
      if (originUser == null || targetRec == null) return null;

      break;
    }

    case FeedItemType.NewMention: {
      if (originUser == null || targetRec == null) return null;

      break;
    }

    case FeedItemType.NewPromptEndorse:
    case FeedItemType.NewPrompt: {
      if (originUser == null || targetPrompt == null) return null;

      // contextNode = (
      //   <HStack
      //     spacing="3px"
      //     alignItems="center"
      //     width="fit-content"
      //     opacity={isBackgroundLight ? 0.3 : 0.8}
      //     _hover={{ opacity: 1 }}
      //   >
      //     <Text
      //       textStyle="brand.headingSm"
      //       as={Link}
      //       href={`/ask/${targetPrompt.id}`}
      //       variant="stylizedNoDefaultBorder"
      //     >
      //       <em>@{originUser.username} ASKED</em>
      //     </Text>
      //   </HStack>
      // );

      mainNode = <PromptItem prompt={targetPrompt} verticallyCentered {...promptProps} />;

      break;
    }

    case FeedItemType.NewPromptRecReply: {
      if (originUser == null || targetPrompt == null || targetRec == null) return null;

      // contextNode = (
      //   <HStack
      //     spacing="3px"
      //     alignItems="center"
      //     width="fit-content"
      //     opacity={isBackgroundLight ? 0.3 : 0.8}
      //     _hover={{ opacity: 1 }}
      //   >
      //     <Text
      //       textStyle="brand.headingSm"
      //       as={Link}
      //       href={`/ask/${targetPrompt.id}`}
      //       variant="stylizedNoDefaultBorder"
      //     >
      //       <em>@{originUser.username} REPLIED TO AN ASK</em>
      //     </Text>
      //   </HStack>
      // );

      mainNode = (
        <RecommendationItem
          rec={targetRec}
          nooflines={noOfLines}
          showPrompt
          withLessPadding
          // withEmojiSameLine
          // withUrlSameLine
          withMinHeight={false}
          {...recProps}
        />
      );

      break;
    }

    case MergedFeedItemType.MERGED_NEW_REC_ENDORSE: {
      if (originUser == null || !targetRec || !('feedItems' in feedItem) || !displayData)
        return null;

      contextNode = (
        <HStack
          spacing="3px"
          alignItems="center"
          width="fit-content"
          opacity={isBackgroundLight ? 0.3 : 0.8}
          _hover={{ opacity: 1 }}
        >
          {/* <Box>
            <NextImage
              alt="endorsements"
              src={`https://files.pi.fyi/one-endorsed-user-endorsed.png`}
              width={15}
              height={15}
            />
          </Box> */}
          <PerfImageFromFile
            file={displayData.imageFile}
            useFallbackAvatarSrc
            alt="image"
            quality={10}
            style={{
              width: `${15}px`,
              height: `${15}px`,
            }}
          />
          <Link
            to={`/rec/${targetRec.id}`}
            className={'typography-heading-sm text-theme-main hover:opacity-80'}
          >
            <em>{displayData?.mainContent}</em>
          </Link>
        </HStack>
      );

      mainNode = (
        <RecommendationItem
          rec={targetRec}
          nooflines={noOfLines}
          showPrompt
          withLessPadding
          withMinHeight={false}
          // withEmojiSameLine={!!targetRec.prompt}
          // withUrlSameLine={!!targetRec.prompt}
          // contextNode={contextNode}
          {...recProps}
        />
      );

      break;
    }

    case MergedFeedItemType.MERGED_RE_REC: {
      console.log('MergedFeedItemType.MERGED_RE_REC!', feedItem);
      return null;
    }

    case MergedFeedItemType.MERGED_COMMENT: {
      console.log('MergedFeedItemType.MERGED_COMMENT!', feedItem);
      return null;
    }

    case MergedFeedItemType.MERGED_PROMPT_REPLY: {
      console.log('MergedFeedItemType.MERGED_PROMPT_REPLY!', feedItem);
      return null;
    }
  }

  return (
    <Stack
      width="100%"
      maxWidth="100%"
      height="100%"
      maxHeight="100%"
      alignSelf="center"
      backgroundColor="brand.background"
    >
      {contextNode && !hideContextForTypes.has(feedItem.type) && !hideAllFeedContext && (
        <Box mb="16px" alignSelf="center">
          {contextNode && mainNode && displayData?.mainTooltip && feedItem.createdAt ? (
            <Tooltip
              openDelay={1000}
              label={
                <Box p="6px">
                  <Text>
                    {feedItem.createdAt ? getRelativeDateString(new Date(feedItem.createdAt)) : ''}
                  </Text>
                </Box>
              }
            >
              {contextNode}
            </Tooltip>
          ) : (
            contextNode
          )}
        </Box>
      )}
      {mainNode && !forceGeneric ? (
        <Box
          width="100%"
          maxWidth="100%"
          height="100%"
          maxHeight="100%"
          flex={1}
          alignSelf="center"
          backgroundColor="brand.background"
        >
          {mainNode}
        </Box>
      ) : (
        <GenericSmallFeedItem
          feedItem={feedItem}
          hideTypes={hideTypes}
          displayData={displayData}
          fullWidth={fullWidth}
        />
      )}
    </Stack>
  );
}

function GenericSmallFeedItem({
  feedItem,
  isGeneric,
  fullWidth,
  hideTypes = new Set([]),
  displayData: _displayData,
}: {
  feedItem: FullFeedItemFragment;
  hideTypes?: Set<string | FeedItemType>;
  isGeneric?: boolean;
  displayData?: FeedItemDisplayData | null;
  fullWidth?: boolean;
}) {
  const displayData =
    _displayData ||
    useFeedItemData({
      feedItem,
      hideTypes,
      isGeneric,
    });

  if (!displayData) {
    return null;
  }

  const {
    imageSrc,
    imageFile,
    mainLink,
    mainContent,
    imageLink,
    imageTooltip,
    originUserFallbackLogoColor,
    mainTooltip,
  } = displayData;

  let imageNode = (imageSrc || originUserFallbackLogoColor) && (
    <NextImage
      src={imageSrc || originUserFallbackLogoColor}
      width={50}
      height={50}
      alt="image"
      quality={10}
      style={{
        width: '50px',
        height: '50px',
        minWidth: '50px',
        minHeight: '50px',
      }}
    />
  );

  if (imageFile) {
    imageNode = (
      <PerfImageFromFile
        file={imageFile}
        useFallbackAvatarSrc
        alt="image"
        quality={10}
        width={50}
        height={50}
        style={{
          width: '50px',
          height: '50px',
          minWidth: '50px',
          minHeight: '50px',
        }}
      />
    );
  }

  const finalImageNode =
    imageNode && (imageLink ? <Link to={imageLink}>{imageNode}</Link> : imageNode);

  const finalContentNode = mainLink && mainContent && (
    <Stack spacing="6px">
      <Link to={mainLink}>
        <Text
          textStyle="brand.headingSm"
          fontSize={{ base: '16px', sm: '18px' }}
          height="fit-content"
          noOfLines={1}
          maxWidth="full"
        >
          {mainContent}
        </Text>
      </Link>
      <Text textStyle="brand.actionSm" opacity={0.2}>
        {formatRelative(new Date(feedItem.createdAt), new Date())}
      </Text>
    </Stack>
  );

  if (!finalContentNode || !finalImageNode) {
    return null;
  }

  const finalFeedItemNode = (
    <HStack
      spacing="6px"
      alignItems="center"
      minWidth="300px"
      width={fullWidth ? '100%' : { base: '200px', md: '400px' }}
      maxWidth={fullWidth ? '100%' : '90%'}
      position="relative"
      overflowX="visible"
      alignSelf="center"
    >
      <Box mr="10px">
        {imageTooltip ? (
          <Tooltip openDelay={1000} label={imageTooltip}>
            {finalImageNode}
          </Tooltip>
        ) : (
          finalImageNode
        )}
      </Box>
      <Tooltip
        openDelay={1000}
        label={
          <Box p="6px">
            <Text>
              {mainTooltip ? `${mainTooltip} /` : ''}{' '}
              {feedItem.createdAt ? getRelativeDateString(new Date(feedItem.createdAt)) : ''}
            </Text>
          </Box>
        }
      >
        {finalContentNode}
      </Tooltip>
      {/* {feedItem.createdAt ? (
        <Box>
          <Text textStyle="brand.actionSm" color="brand.lightgrey">
            {formatRelative(new Date(feedItem.createdAt), new Date())}
          </Text>
        </Box>
      ) : null} */}
    </HStack>
  );

  return finalFeedItemNode;
}
