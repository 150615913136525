export const isDev = process.env['NODE_ENV'] === 'development';
export const isProd = process.env['NODE_ENV'] === 'production';
export const isStaging = process.env['NODE_ENV'] === 'staging';

const FALLBACK =
  process.env['APP_VARIANT'] === 'development' || process.env['NODE_ENV'] === 'development'
    ? process.env.EAS_BUILD_PLATFORM === 'android'
      ? 'http://10.0.2.2:3001'
      : 'http://localhost:3001'
    : 'https://www.pi.fyi';

const CLOUD_TEST_URL = 'https://peninsula-sacred-whereas-approval.trycloudflare.com';

const FALLBACK_APP_TEST_URL = isDev ? CLOUD_TEST_URL : '';

export const GRAPHQL_SERVER_BASE_URL =
  process.env['NODE_ENV'] === 'development' ? 'http://localhost:3002' : 'https://gql.pi.fyi';

export const GRAPHQL_SERVER_URL =
  process.env['NODE_ENV'] === 'development'
    ? 'http://localhost:3002/api/graphql'
    : 'https://gql.pi.fyi/api/graphql';

export const BASE_SITE_URL =
  // (isDev && !!process.env['APP_VARIANT'] ? CLOUD_TEST_URL : '') ||
  process.env['BASE_SITE_URL'] ||
  process.env['RENDER_EXTERNAL_URL'] ||
  process.env['SITE_URL'] ||
  process.env.EXPO_PUBLIC_SITE_URL ||
  (isProd ? 'https://www.pi.fyi' : '') ||
  // 'https://www.pi.fyi';
  // ((process.env['APP_VARIANT'] === 'development' || process.env['NODE_ENV'] === 'development') &&
  FALLBACK;

export const IS_REDESIGN = true;
