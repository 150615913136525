import { createFileRoute, redirect } from '@tanstack/react-router';
import { handleLinkTracking } from '../../actions/handleLinkTracking';
import { z } from 'zod';
import { replicate } from 'database';

export const Route = createFileRoute('/l/$url')({
  validateSearch: z.object({
    userId: z.string(),
    emailId: z.string(),
  }),
  loaderDeps: ({ search: { userId, emailId } }) => ({
    userId,
    emailId,
  }),
  loader: async ({ params, deps }) => {
    const { userId, emailId } = deps;

    const url = params.url;

    let finalUrl = await handleLinkTracking({
      data: {
        url,
        userId,
        emailId,
      },
    });

    if (finalUrl.trim() === 'https:') {
      throw redirect({ to: '/' });
    }

    if (!finalUrl || finalUrl.includes('/p/null')) {
      throw redirect({ to: '/read' });
    }

    throw redirect({ href: finalUrl });
  },
});
