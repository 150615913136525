import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useRouter } from '@tanstack/react-router';
import _ from 'lodash';
import { gql } from 'shared/__generated__/gql';
import { UserTileFragment } from 'shared/__generated__/graphql';
import PerfImageFromFile from 'web2/app/components/by-type/images/perf-image-from-file';
import { useThemeColors } from 'shared/misc/hooks/useThemeColors';
import Heading from 'shared/misc/components/heading';
import { getEmojisAsArrayFromString } from 'shared/emoji';
import { useAuth } from 'shared/misc/hooks/useAuth';
import useFollowUser from 'shared/misc/hooks/useFollowUser';
import { useIsBackgroundLight } from 'shared/misc/hooks/useContrastHighlight';
import { Button } from 'web2/app/components/button';
import { Tooltip, TooltipContent, TooltipTrigger } from 'web2/app/components/ui/tooltip';
import { TWLink } from '../../link';
import { ProfileThemeType } from 'shared/__generated__/graphql';
import { twMerge } from 'tailwind-merge';
gql(/* GraphQL */ `
  fragment UserTile on User {
    ...UserDirectoryItem
    ...UserCore
    amIFollowing
    profileTheme
    areTheyFollowing
    avatarPhotoFile {
      ...PerfImageFromFile
    }
    recs(first: 5, includePromptRecs: true) {
      id
      emoji
    }
  }
`);

type Props = {
  size?: 'lg' | 'sm';
  user: UserTileFragment;
  priority?: boolean;
  skipStars?: boolean;
};

function UserTile({ user, size = 'sm', priority = false, skipStars }: Props) {
  const href = `/u/${user.username}`;

  const themeColors = useThemeColors();

  const auth = useAuth();
  const router = useRouter();

  const colors = useThemeColors(user.profileTheme || ProfileThemeType.Default);

  // const isToday =
  //   isAfter(sub(new Date(), { days: 1 }), new Date(feature.date)) &&
  //   isBefore(add(new Date(), { days: 1 }), new Date(feature.date));

  const { followUser, unfollowUser } = useFollowUser({ user });

  return (
    <div
      role="group"
      className="flex flex-col gap-[6px] border border-dashed overflow-hidden justify-center h-full pb-[6px]"
      style={{
        borderColor: colors.highlight,
        backgroundColor: colors.background,
      }}
    >
      <div className="cursor-pointer w-full self-center p-[6px]">
        <TWLink
          to={'/u/$username'}
          params={{ username: user.username }}
          className="w-full flex flex-col justify-center items-center"
        >
          <div className="flex sm:w-full pb-1 max-h-fit">
            <PerfImageFromFile
              file={user.avatarPhotoFile}
              backgroundColor={colors.background}
              useFallbackAvatarSrc
              alt="profile photo"
              quality={80}
              // priority={inView}
              style={{
                aspectRatio: 1,
                width: '100%',
                objectFit: 'cover',
                objectPosition: 'top',
              }}
            />
          </div>
        </TWLink>
      </div>
      <div className="relative flex flex-col justify-center items-center px-2 pb-[6px] w-full self-center min-h-[50px]">
        <h2
          style={{
            ...(colors.titleBorder && {
              WebkitTextStroke: `1px ${colors.titleBorder}`,
            }),
            color: colors.titleBackground,
          }}
          className={twMerge(
            'text-[18px] w-full text-center line-height-1 line-clamp-1 break-all',
            'font-abc-favorite',
            'typography-heading-xl',
            'text-title-background',
          )}
        >
          @{user.username}
        </h2>
        <div className="flex flex-row gap-[6px] justify-center items-center w-full">
          <div
            className="typography-action-sm text-center break-all text-main pt-[6px] line-clamp-1 max-w-[50%]"
            style={{
              color: colors.main,
            }}
          >
            {user.location || 'Earth'}
          </div>
          <Tooltip>
            <TooltipContent className="text-brand-main typography-action-sm">
              Recent recs.
            </TooltipContent>
            <TooltipTrigger>
              <div
                className="typography-action-sm text-center whitespace-nowrap text-base pt-[6px]"
                style={{
                  color: colors.main,
                  opacity: user?.recs?.length ? 1 : 0.4,
                }}
              >
                {user?.recs?.length
                  ? getEmojisAsArrayFromString(
                      user?.recs?.reduce((acc, r) => `${acc}${r.emoji?.trim()}`, ''),
                    )
                      .slice(0, 5)
                      .join(' ')
                      .trim()
                  : 'No recs yet..'}
              </div>
            </TooltipTrigger>
          </Tooltip>
        </div>
      </div>
      <div className="flex flex-row w-[calc(100%-24px)] self-center">
        <Button
          variant="primary"
          className="w-[calc(60%-4px)] h-fit p-[3px] mr-[4px] disabled:opacity-50"
          isDisabled={auth.user?.id === user.id}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (auth.user) {
              if (user.amIFollowing) {
                unfollowUser();
              } else {
                followUser();
              }
            } else {
              router.navigate({ to: '/sign-in' });
            }
          }}
          style={{
            color: colors.background,
            backgroundColor: colors.highlight,
            border: '1px solid transparent',
          }}
        >
          {auth.user && user.amIFollowing
            ? 'Following'
            : user.id === auth.user?.id
            ? `That's you!`
            : 'Follow'}
        </Button>
        <Button
          className="w-[calc(40%-4px)] h-fit p-[3px] ml-[4px] disabled:opacity-50"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            router.navigate({ to: href });
          }}
          style={{
            color: colors.highlight,
            backgroundColor: colors.background,
            borderWidth: '1px',
            borderColor: colors.highlight,
          }}
        >
          View
        </Button>
      </div>
    </div>
  );
}

const FallBackImage = ({ withSkeleton }: { withSkeleton?: boolean }) => {
  return (
    <div
      className="flex w-full object-cover aspect-square object-[0_0] justify-center items-center"
      style={
        !withSkeleton
          ? {
              backgroundColor: 'brand.highlight',
            }
          : {}
      }
    >
      {withSkeleton && (
        <div className="h-full w-full bg-gradient-to-r from-brand-highlight to-[#0096FF] animate-pulse" />
      )}
    </div>
  );
};

export default UserTile;
