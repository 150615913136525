import prisma from 'database';
import { createServerFn } from '@tanstack/react-start';

export const isUniqueUsername = createServerFn({ method: 'GET' }).handler(
  async ({ data }: { data: { username: string } }) => {
    const user = await prisma.user.findFirst({
      where: {
        username: {
          equals: data.username,
          mode: 'insensitive',
        },
      },
    });

    console.log('username', data.username);

    console.log('user', user);

    return !user;
  },
);

export const userExistsWithUsername = createServerFn({ method: 'GET' }).handler(
  async ({ data }: { data: { username: string } }) => {
    const user = await prisma.user.findFirst({
      where: {
        username: {
          equals: data.username,
          mode: 'insensitive',
        },
      },
    });

    return !!user;
  },
);
