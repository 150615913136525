import { Outlet, createRootRouteWithContext } from '@tanstack/react-router';

import { RootProviders } from '../misc/wrappers/RootProviders';
import { SetTheme } from '../../../web/src/app/SetTheme';
import { ThemeColorMap, generateThemeClasses } from 'shared/misc/theme/custom/custom-theme-colors';
import { generateRootCssVariables } from 'shared/misc/theme/colors';
import { HeadContent, Scripts } from '@tanstack/react-router';
import { ColorModeScript } from '@chakra-ui/react';
import { getInitialTheme } from '../actions/getTheme';
import { getSessionUser } from '../actions/getSessionUser';
import appCss from '../main.css?inline';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { useEffect, useState } from 'react';
import { ProfileThemeType, SignedInUserFragment } from 'shared/__generated__/graphql';

import { getSSRBreakpointFromUAString } from '../actions/getSSRBreakpointFromUAString';
import { BreakpointProvider } from 'shared/misc/providers/breakpoint-provider';
import { type ApolloClientRouterContext } from '@apollo/client-integration-tanstack-start';
import { genCDN } from 'shared/cdn';
import NotFound from '../components/not-found';
import Woops from '../components/error';
import { genMeta } from '../utils/meta';
import * as Sentry from '@sentry/react';
export const Route = createRootRouteWithContext<ApolloClientRouterContext>()({
  component: RootDocument,
  errorComponent: ({ error }) => {
    return (
      <html lang="en">
        <head>
          <style
            key="critical-styles"
            dangerouslySetInnerHTML={{
              __html: `
                ${generateThemeClasses()}
                ${generateRootCssVariables(ThemeColorMap[ProfileThemeType.Default] || {})}
                ${appCss}
              `,
            }}
          />
          <Favicons />
        </head>
        <body className="theme-default chakra-ui-light">
          <CacheProvider value={createCache({ key: 'css' })}>
            <BreakpointProvider initialBreakpoint="md">
              <RootProviders sessionUser={null} theme={ProfileThemeType.Default}>
                {typeof window === 'undefined' ? null : <SetTheme />}
                <Woops error={error} />
              </RootProviders>
            </BreakpointProvider>
          </CacheProvider>
        </body>
      </html>
    );
  },
  notFoundComponent: () => <NotFound />,
  head: (args) => {
    const image = genCDN('pi-image-for-social.png', 70, 'webp');

    const meta = genMeta({
      title: 'Perfectly Imperfect | PI.FYI',
      description: 'A TASTE OF TASTE',
      image,
      isRoot: true,
    });
    return meta;
  },
  staleTime: 3 * 1000,
  loader: async ({ params, location, context }) => {
    try {
      const user = await getSessionUser().catch((e) => {
        console.log(e);
        return null;
      });

      const theme = await getInitialTheme(context.apolloClient, user, location.pathname);

      const breakpoint: 'md' | 'sm' | 'lg' | 'xl' | '2xl' = await getSSRBreakpointFromUAString();

      return {
        theme,
        user,
        breakpoint,
      };
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      return {
        theme: ProfileThemeType.Default,
        user: null,
      };
    }
  },
});

function RootDocument({ children }: Readonly<{ children: React.ReactNode }>) {
  const loaderData = Route.useLoaderData();
  const theme = loaderData?.theme as ProfileThemeType;
  const emotionCache = createCache({ key: 'css' });

  const colors = ThemeColorMap[theme || ProfileThemeType.Default] || {};

  return (
    <html data-theme="light" lang="en" style={{ colorScheme: 'light' }}>
      <head>
        {/* <style
          key="critical-styles"
          // data-emotion="critical"
          dangerouslySetInnerHTML={{
            __html: `
              ${generateThemeClasses()}
              ${generateRootCssVariables(colors)}
              ${appCss}
            `,
          }}
        /> */}

        <Favicons />
        <HeadContent />
        <style
          key="critical-styles"
          // data-emotion="critical"
          dangerouslySetInnerHTML={{
            __html: `
              ${generateThemeClasses()}
              ${generateRootCssVariables(colors)}
              ${appCss}
            `,
          }}
        />
      </head>

      <body className={`theme-${theme?.toLowerCase()} chakra-ui-light`}>
        <ColorModeScript type="localStorage" initialColorMode="light" />
        <CacheProvider value={emotionCache}>
          <BreakpointProvider initialBreakpoint={loaderData?.breakpoint}>
            <RootProviders
              sessionUser={loaderData?.user as SignedInUserFragment}
              theme={loaderData?.theme as ProfileThemeType}
            >
              {typeof window === 'undefined' ? null : <SetTheme />}
              {/* <div className="flex flex-col gap-y-4">
                <div>
                  <p>hello world</p> <Outlet />
                </div>
                <div>
                  <p>wtf is going on here</p>
                </div>
              </div> */}
              <Outlet />
            </RootProviders>
          </BreakpointProvider>
        </CacheProvider>
        <Scripts />
      </body>
    </html>
  );
}

function NoSSR({ children }: Readonly<{ children: React.ReactNode }>) {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);
  if (!isClient) {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-theme-background gap-y-4">
        <div className="w-12 h-12 border-4 border-theme-menu-logo rounded-full border-t-transparent animate-spin" />
        <noscript>
          <h1 className="text-2xl font-bold text-theme-menu-logo">
            You need javascript to use this app!
          </h1>
        </noscript>
      </div>
    );
  }
  return <>{isClient ? children : null}</>;
}

function Favicons() {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return null;
  }
  return (
    <>
      {window.location.host === 'www.pi.fyi' && (
        <link rel="icon" type="image/x-icon" href={'/favicon.ico'} />
      )}
      {window.location.host === 'staging.pi.fyi' && (
        <link rel="icon" type="image/x-icon" href={'/favicon-staging.png'} />
      )}

      {window.location.host.includes('localhost') && (
        <link rel="icon" type="image/x-icon" href={'/favicon-dev.png'} />
      )}
      {window.location.host.includes('localhost') && null}
    </>
  );
}
