import { useRouterState } from '@tanstack/react-router';
import { useEffect, useState, useMemo, ReactNode } from 'react';
import { gql } from 'shared/__generated__';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { ModalNames, useModalState } from '../misc/wrappers/modal-provider';
import { Portal, Popover, PopoverContent, PopoverTrigger } from '../components/ui/popover';
import { Link } from '@tanstack/react-router';
import { FiMenu } from 'react-icons/fi';
import { RiCloseLine } from 'react-icons/ri';
import { twMerge } from 'tailwind-merge';
import { useAnalytics } from 'shared/misc/providers/AnalyticsContext';
import _ from 'lodash';
import { GetHomeSidebarFeedDocument, FeedItemType } from 'shared/__generated__/graphql';
import { InView } from 'react-intersection-observer';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import FeedItem from '../components/by-type/feed/sidebar-feed-item';
import SkeletonSidebarFeedItem from 'shared/misc/components/SkeletonSidebarFeedItem';
import { Button as TWButton } from './button';
import { useSearchParams } from '../utils/tan-router';

const TYPES = [
  FeedItemType.AddRecommendation,
  FeedItemType.NewUser,
  FeedItemType.NewPrompt,
  FeedItemType.NewComment,
  FeedItemType.NewRecEndorse,
  FeedItemType.NewUserFollow,
  FeedItemType.NewRePost,
  FeedItemType.NewRePostWithContent,
  FeedItemType.NewPromptRecReply,
];

const WELCOME_TYPES = [
  FeedItemType.AddRecommendation,
  FeedItemType.NewUser,
  FeedItemType.NewPrompt,
];

gql(/* GraphQL */ `
  query getHomeSidebarFeed(
    $first: Int
    $after: String
    $showGlobalFeed: Boolean
    $types: [FeedItemType!]
  ) {
    feedConnection(first: $first, after: $after, showGlobalFeed: $showGlobalFeed, types: $types)
      @connection(key: "HomeSidebarFeed") {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...SidebarFeedItem
        }
      }
    }
  }
`);

gql(/* GraphQL */ `
  query getHomeSidebarEveryoneFeed($first: Int, $after: String, $types: [FeedItemType!]) {
    feedConnection(first: $first, after: $after, types: $types, showGlobalFeed: true)
      @connection(key: "HomeSidebarEveryoneFeed") {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...SidebarFeedItem
        }
      }
    }
  }
`);

const NavNotificationViewQuery = gql(/* GraphQL */ `
  query getNavNotificationViewQuery($first: Int, $after: String, $types: [String!]) {
    notificationConnection(first: $first, after: $after, types: $types)
      @connection(key: "mobileNotificationFeed") {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...NotificationFeedItem
        }
      }
    }
  }
`);

const MARK_ALL_AS_SEEN_MUTATION = gql(/* GraphQL */ `
  mutation navMarkAllAsSeen {
    markAllAsSeen {
      id
      unseenNotificationCount
    }
  }
`);

const PageQuery = gql(/* GraphQL */ `
  query getNotificationCount {
    me {
      id
      unseenNotificationCount
      unseenConversationNotificationCount
    }
  }
`);

const usePathname = () => {
  const pathname = useRouterState({
    select: (state) => state.location.pathname,
  });

  return pathname;
};

function NavFeed({ isPaused }: { isPaused: boolean }) {
  const pathname = usePathname();

  const { data, loading, networkStatus } = useQuery(GetHomeSidebarFeedDocument, {
    variables: {
      first: 20,
      showGlobalFeed: true,
      // @ts-ignore
      types: pathname === '/welcome' ? WELCOME_TYPES : TYPES,
    },
    fetchPolicy: 'cache-first',
    initialFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    pollInterval: 60000,
  });

  const isRefetching =
    networkStatus === NetworkStatus.refetch || networkStatus === NetworkStatus.fetchMore;

  const [isHovered, setIsHovered] = useState(false);

  const feedItems = data?.feedConnection.edges;
  const duration = 30;

  return (
    <div className="flex w-full h-full overflow-hidden justify-center">
      <div
        className="relative flex flex-row max-h-full space-x-1 items-center"
        style={{
          animation: `scrollAnimation ${duration}s linear infinite`,
          animationPlayState: isPaused
            ? 'paused'
            : isHovered || feedItems?.length === 0
            ? 'paused'
            : 'running',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {!data && loading && !isRefetching
          ? [...Array(25)].map((_, index) => <SkeletonSidebarFeedItem key={index} size={'small'} />)
          : feedItems?.map(({ node }) => (
              <FeedItem key={node.id} feedItem={node} truncateLength={50} />
            ))}
        {data && feedItems?.length === 0 && (
          <p className="text-brand-bodySm text-secondaryText pt-12">No activity yet...</p>
        )}
      </div>
    </div>
  );
}

const Spacer = () => <div className="pt-[50px]" />;
const PAGE_LENGTH = 20;

function NavNotificationFeed({ onClose }: { onClose: () => void }) {
  const { data, loading, refetch, fetchMore } = useQuery(NavNotificationViewQuery, {
    variables: {
      first: PAGE_LENGTH,
      // excludeTypes: [FeedItemType.]
    },
    fetchPolicy: 'cache-and-network',
    // pollInterval: 60000,
    notifyOnNetworkStatusChange: true,
  });

  const feedItems = data?.notificationConnection?.edges;

  const auth = useAuth();

  const [markAllAsSeen] = useMutation(MARK_ALL_AS_SEEN_MUTATION, {
    optimisticResponse: {
      __typename: 'Mutation',
      markAllAsSeen: {
        __typename: 'User',
        id: auth.user?.id!,
        unseenNotificationCount: 0,
      },
    },
  });

  useEffect(() => {
    markAllAsSeen();
  }, []);

  const getNextPage = async () => {
    if (!data?.notificationConnection.pageInfo.hasNextPage) return;
    await fetchMore({
      variables: {
        first: PAGE_LENGTH,
        after: data?.notificationConnection.pageInfo.endCursor,
      },
    });
  };

  return (
    <div className="flex w-full h-full justify-start overflow-y-auto pl-[24px] bg-brand-menu-background typography-action-sm text-brand-menu-text">
      <div className="flex flex-col gap-[4px] max-h-full border-none" onClick={onClose}>
        {!data && loading
          ? [...Array(15)].map((_, index) => <SkeletonSidebarFeedItem key={index} size={'small'} />)
          : feedItems?.map(({ node }) => (
              <FeedItem
                key={node.id}
                feedItem={node}
                showHasSeenDot
                truncateLength={45}
                highlightText
              />
            ))}
        {data && feedItems?.length === 0 && (
          <p className="text-brand-bodySm text-secondaryText pt-12">No activity yet...</p>
        )}
        <InView
          as="div"
          rootMargin="50px 0px"
          onChange={(inView) => {
            if (inView) {
              getNextPage();
            }
          }}
        />
        <Spacer />
      </div>
    </div>
  );
}

export default function Nav({ children }: { children: ReactNode }) {
  const { user } = useAuth();
  const pathname = usePathname();
  const [isOpen, setIsOpen] = useState(false);
  const { trackEvent } = useAnalytics();

  const [isNotificationInnerView, setIsNotificationInnerView] = useState<boolean>(false);
  const searchParams = useSearchParams();
  const addRecommendationModalState = useModalState(ModalNames.ADD_RECOMMENDATION);
  const addAskModalState = useModalState(ModalNames.ADD_ASK);

  const isSpecialPage = ['/welcome', '/sign-in'].includes(pathname);

  const ask = () => {
    setIsOpen(false);
    addAskModalState.onOpen();
  };

  const share = () => {
    setIsOpen(false);
    addRecommendationModalState.onOpen();
  };

  const { data } = useQuery(PageQuery, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 30000,
  });

  const linkConfig = useMemo(() => {
    return [
      { name: 'HOME', href: '/' },
      { name: 'FEED', href: '/home/everyone' },
      { name: 'EDITORIAL', href: '/read' },
      ...(!user
        ? [
            { name: 'Sign in', href: '/sign-in' },
            { name: 'Join PI.FYI', href: '/sign-up' },
          ]
        : []),
      ...(!user?.deniedNotifications && !user?.expoPushToken
        ? [
            {
              name: 'THE PI.FYI APP',
              isSpecial: !user?.deniedNotifications && !user?.expoPushToken,
              href: 'https://apps.apple.com/us/app/pi-fyi-by-perfectly-imperfect/id6474037926',
              external: true,
              onClick: () => {
                trackEvent('Click', 'Download_App', {
                  userId: user?.id,
                });
              },
            },
          ]
        : []),
      { name: 'SEARCH', href: '/search' },
      { name: 'DIRECTORY', href: '/directory' },
      ...(user
        ? [
            {
              name: (
                <>
                  <span
                    className={twMerge(
                      data?.me?.unseenConversationNotificationCount || 0 > 1
                        ? 'text-theme-menu-highlight'
                        : 'text-theme-menu-text',
                    )}
                  >
                    CONVOS
                  </span>
                  {data?.me?.unseenConversationNotificationCount || 0 > 1 ? (
                    <span className="bg-menu-higlight px-[2px] text-menu-background">
                      {data?.me?.unseenConversationNotificationCount}
                    </span>
                  ) : null}
                </>
              ),
              href: `/convos`,
            },
          ]
        : []),
      ...(user
        ? [
            {
              name: 'PROFILE',
              href: `/u/${user.username}`,
              links: [
                { name: 'SETTINGS', href: `/settings` },
                ...(!user?.isPremium
                  ? [
                      {
                        name: 'UPGRADE',
                        isSpecial: true,
                        href: `/settings/premium`,
                      },
                    ]
                  : []),
                { name: 'LIKED', href: `/endorsed` },
                { name: 'SAVED', href: `/bookmarks` },
                { name: 'LISTS', href: `/u/${user.username}/lists` },
                { name: 'DRAFTS', href: `/drafts` },
                { name: 'INVITES', href: '/invites' },
              ],
              addSpacer: user?.role !== 'ADMIN',
            },
          ]
        : []),
      { name: 'ASKS', href: '/asks' },
      { name: 'RANDOM', href: '/random' },
      ...(user?.role === 'ADMIN'
        ? [
            {
              name: 'ADMIN',
              href: '/admin',
              addSpacer: true,
            },
          ]
        : []),
      {
        name: 'About',
        href: '/what-is-this',
        style: 'small',
      },
      {
        name: 'Careers',
        href: '/careers',
        style: 'small',
      },
      {
        name: 'Contact',
        href: 'mailto:admin@perfectlyimperfect.fyi?subject=Perfectly%20Imperfect',
        external: true,
        style: 'small',
      },
      {
        name: 'Privacy',
        href: 'https://files.pi.fyi/privacy.pdf',
        style: 'small',
      },
      ...(!user
        ? []
        : [
            {
              name: 'Sign out',
              href: '/sign-out',
              style: 'small',
            },
          ]),
    ];
  }, [user, data?.me?.unseenNotificationCount]);

  const MenuIcon = useMemo(() => {
    return isOpen ? RiCloseLine : FiMenu;
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  useEffect(() => {
    if (!isOpen && isNotificationInnerView) {
      setIsNotificationInnerView(false);
    }
  }, [isOpen]);

  useEffect(() => {
    // scroll lock on the rest of the page when the menu is open
    if (isOpen) {
      // Save current scroll position
      const scrollY = window.scrollY;
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = '100%';
      document.body.style.overflow = 'hidden';
    } else {
      // Restore scroll position
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      document.body.style.overflow = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  }, [isOpen]);

  /**
   * 
  *  const isMobile = ['sm', 'base'].includes(breakpoint);
   * const isTablet = ['md',].includes(breakpoint);
   * const isDesktop = ['lg', 'xl', '2xl'].includes(breakpoint);
   * 
   *  '2xl': window.matchMedia('(min-width: 1536px)'),
      xl: window.matchMedia('(min-width: 1280px)'),
      lg: window.matchMedia('(min-width: 1024px)'),
      md: window.matchMedia('(min-width: 768px)'),
      sm: window.matchMedia('(min-width: 640px)'),
   * 
   */

  const bodyClasses = twMerge(
    'w-full min-h-[calc(100dvh-64px)] bg-brand-background z-[500] mt-[64px] relative xl:items-start xl:flex xl:justify-between xl:[&>div]:flex-1 xl:[&>div]:mx-auto xl:[&>div]:w-full',
    isOpen ? 'brightness-[1.5] grayscale contrast-[10]' : '',
  );

  const hasNewNotifications = (data?.me?.unseenNotificationCount || 0) > 0;

  return (
    <>
      <nav
        id="nav-container"
        className="w-full h-[64px] overflow-x-hidden flex items-center padding-0 border-none fixed z-[501] top-0 justify-between overscroll-contain bg-theme-background"
      >
        {!isSpecialPage && (
          <div
            className={twMerge(
              'h-[64px] w-full md:w-[393px] md:min-w-[393px] flex pl-[24px] md:pl-0 justify-between md:justify-around items-center border-r-[1px] border-b-[1px] relative',
              !isOpen
                ? 'border-dashed border-brand-main'
                : 'bg-brand-menu-background border-transparent',
            )}
          >
            <Popover open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
              <PopoverTrigger asChild>
                <button
                  className={twMerge(
                    'h-[56px]',
                    isOpen ? 'text-brand-menu-logo' : 'text-brand-logo-color',
                  )}
                >
                  <MenuIcon fontSize={16} />
                </button>
              </PopoverTrigger>
              <Portal>
                <PopoverContent
                  id="nav-popover"
                  className="w-[100dvw] h-[100dvh] md:w-[393px] md:h-[310px] top-[0] overflow-y-auto z-[501]"
                  style={{ border: 'none' }}
                >
                  <div className="w-full h-full bg-brand-menu-background">
                    <TWButton
                      variant="textOnly"
                      className={twMerge(
                        'h-[32px] absolute right-[24px] top-[-48px] hover:opacity-100 bg-brand-menu-background z-[501] outline-none border-none',
                        isOpen
                          ? 'bg-brand-menu-background text-brand-menu-text hover:text-brand-menu-highlight'
                          : 'bg-brand-background text-brand-main-text hover:text-brand-highlight',
                        data?.me?.unseenNotificationCount ? 'text-brand-menu-logo' : '',
                      )}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setIsNotificationInnerView((prev) => !prev);
                      }}
                    >
                      {isNotificationInnerView
                        ? 'DONE'
                        : isOpen || hasNewNotifications
                        ? `${data?.me?.unseenNotificationCount || 0} NEW`
                        : ''}
                    </TWButton>
                    {isNotificationInnerView ? (
                      <NavNotificationFeed onClose={() => setIsOpen(false)} />
                    ) : (
                      <ul className="pl-[32px] pt-[8px] pb-[16px] flex border-radius-[0px] z-[501] bg-brand-menu-background border-[0px] mt-[0px] outline-none border-none flex flex-col gap-[8px]">
                        {linkConfig.map((link) => (
                          <li className="typography-action-sm min-h-[20px]" key={link.href}>
                            <Link
                              className={twMerge(
                                'py-[8px] min-h-[20px] text-theme-menu-text hover:text-theme-menu-highlight',
                                link.style === 'small' ? 'text-[13px]' : 'text-[18px]',
                              )}
                              to={link.href}
                            >
                              {link.name}
                            </Link>
                            {link.links ? (
                              <ul className="pl-[16px] flex flex-col gap-[8px] py-[8px]">
                                {link.links.map((sublink) => (
                                  <li
                                    className="hover:text-theme-menu-highlight"
                                    key={sublink.href}
                                  >
                                    <Link
                                      className="typography-action-sm text-theme-menu-text hover:text-theme-menu-highlight !text-[18px]"
                                      to={sublink.href}
                                    >
                                      {sublink.name}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            ) : null}
                          </li>
                        ))}
                      </ul>
                    )}
                    {user && !isSpecialPage && (
                      <div
                        className="w-full absolute bottom-[64px] md:bottom-[-64px] h-[64px] items-center justify-center gap-0 flex lg:hidden"
                        id="sidebar-buttons"
                      >
                        <TWButton
                          variant={'secondary'}
                          className="h-[64px] flex-1 p-[8px] hover:opacity-100 bg-brand-background"
                          onMouseDown={ask}
                        >
                          ASK
                        </TWButton>
                        <TWButton
                          variant="primary"
                          className="h-[64px] flex-1 p-[8px] hover:opacity-100"
                          onMouseDown={share}
                        >
                          SHARE
                        </TWButton>
                      </div>
                    )}
                  </div>
                </PopoverContent>
              </Portal>
            </Popover>
            <Link to="/" className="text-theme-menu-logo inline-block h-[29px]">
              <div
                className={isOpen ? 'bg-brand-menu-logo' : 'bg-brand-logo-color'}
                style={{
                  width: '210px',
                  height: '29px',
                  maskImage: 'url(/static/logo-horizontal.png)',
                  maskSize: 'contain',
                  maskRepeat: 'no-repeat',
                  maskComposite: 'alpha',
                }}
              />
            </Link>
            <span className="w-[50px] md:w-[18px]"></span>
            {!isOpen && hasNewNotifications && (
              <div className="h-[24px] absolute right-[16px] px-[6px] rounded-[4px] flex items-center justify-center bg-brand-highlight">
                <TWButton
                  variant="textOnly"
                  className={twMerge(
                    'hover:opacity-100 z-[502]',
                    isOpen
                      ? 'bg-brand-menu-background text-brand-menu-text hover:text-theme-menu-highlight'
                      : 'text-brand-background',
                  )}
                  onClick={() => {
                    setIsNotificationInnerView(true);
                    setIsOpen(true);
                  }}
                >
                  {data?.me?.unseenNotificationCount || 0} NEW
                </TWButton>
              </div>
            )}
          </div>
        )}
        <div
          className={twMerge(
            'z-[501] flex items-center border-b-[1px] border-dashed border-brand-main flex-1 h-[64px] bg-brand-background md:flex',
            isOpen ? 'brightness-[1.5] grayscale contrast-[10]' : '',
            !isSpecialPage ? 'hidden' : '',
          )}
        >
          <NavFeed isPaused={isOpen} />
        </div>
        {user && !isSpecialPage && (
          <div
            className={twMerge(
              'absolute z-[502] right-0 w-[200px] min-w-[200px] h-[64px] flex items-center justify-center gap-0 hidden lg:flex',
              isOpen ? 'brightness-[1.5] grayscale contrast-[10]' : '',
            )}
            id="sidebar-buttons"
          >
            <TWButton
              variant="secondary"
              className="h-[64px] flex-1 p-[8px] hover:opacity-100 bg-brand-background"
              onMouseDown={ask}
            >
              ASK
            </TWButton>
            <TWButton
              variant="primary"
              className="h-[64px] flex-1 p-[8px] hover:opacity-100"
              onMouseDown={share}
            >
              SHARE
            </TWButton>
          </div>
        )}
      </nav>
      <div id="body-container" className={bodyClasses}>
        {children}
      </div>
    </>
  );
}
