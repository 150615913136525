import React from 'react';
import { Modal, ModalContent, ModalOverlay, ModalCloseButton } from '../ui/modal';
import { ModalNames, useModalState } from '../../misc/wrappers/modal-provider';
import { SubmitHandler, useForm } from 'react-hook-form';
import { signUpEmailOnlyStep2Schema } from 'shared/validation/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import PerfInput from '../ui/perf-input';
import { Link as TWLink } from '../link';
import discoveryList from 'shared/misc/discovery';
import { isUniqueUsername } from '../../actions/isUniqueUsername';
import { useMutation } from '@apollo/client';
import { SIGN_UP_EMAIL_ONLY_STEP_2 } from 'shared/misc/graphql/SignUpFragments';
import { useAuth } from 'shared/misc/hooks/useAuth';
import { usePathname, useSearchParams } from 'shared/misc/hooks/routerHooks';

import { useRouter } from '@tanstack/react-router';
import { TWButton } from '../button';
import { useToast } from '@chakra-ui/react';

type Inputs = {
  username: string;
  password: string;
  confirmPassword: string;
  discoverySource: string;
};

export default function FinishSignUpModal() {
  const { isOpen, onOpen, onClose } = useModalState(ModalNames.FINISH_SIGN_UP);

  const [signUpBasicInfoMutation, { loading, data }] = useMutation(SIGN_UP_EMAIL_ONLY_STEP_2);

  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(signUpEmailOnlyStep2Schema),
    defaultValues: {
      username: '',
      discoverySource: '',
    },
  });

  const auth = useAuth();

  const values = watch();

  const router = useRouter();

  const pathname = usePathname();

  const modalMLR = { base: 0, lg: 16 };
  const modalMTB = { base: 0, lg: 16 };
  const toast = useToast();

  const onSubmit: SubmitHandler<Inputs> = async (
    { confirmPassword: _confirmPassword, ...values },
    event,
  ) => {
    event?.preventDefault();

    console.log('values', values);

    const isUniqueUsernameValid = await isUniqueUsername({ data: { username: values.username } });

    if (!isUniqueUsernameValid) {
      setError('username', { message: 'Username taken.' });
      return;
    }

    await signUpBasicInfoMutation({
      variables: { input: { ...values } },
      onError: () => {
        toast({
          title: 'An error occured...',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      },
    });
  };

  const handleClose = () => {
    onClose();
    if (pathname.includes('/welcome')) {
      router.navigate({ to: '/' });
    }
  };

  const IS_DONE = !!data?.signUpEmailOnlyStep2?.id;
  if (!isOpen) return null;
  return (
    <Modal open={isOpen} onOpenChange={(o) => (o ? onOpen() : handleClose())}>
      <ModalOverlay />
      <ModalContent className="w-full sm:w-[400px]">
        <div className="overflow-y-scroll pb-6 pt-6">
          {IS_DONE ? (
            <div className="flex flex-col gap-[12px] justify-center items-center mt-[12px]">
              <p className="typography-heading-lg text-brand-highlight">YOU'RE ALL SET!</p>
              <p className="typography-heading-sm leading-[140%] text-center">
                You can add a profile photo, and more, on the{' '}
                <TWLink
                  to="/settings"
                  className="text-brand-highlight hover:underline"
                  onClick={() => onClose()}
                >
                  Settings
                </TWLink>{' '}
                page.
              </p>
              <div className="flex flex-col gap-[12px] justify-center items-center mt-[12px]">
                <div className="flex flex-row gap-3">
                  <TWButton
                    variant="secondary"
                    size="md"
                    className="text-[24px]"
                    onClick={handleClose}
                  >
                    Let me in
                  </TWButton>
                </div>
                <a href="https://apps.apple.com/us/app/pi-fyi-by-perfectly-imperfect/id6474037926">
                  <img
                    src="https://files.pi.fyi/App_Store_Badge.png"
                    alt="star-blue"
                    width={500}
                    height={160}
                    style={{
                      maxWidth: '200px',
                    }}
                  />
                </a>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-[12px] w-full max-w-[400px]">
                <div className="flex flex-col gap-[12px] mb-[12px]">
                  <p className="typography-heading-sm text-brand-highlight">YOU'RE SIGNED UP!</p>
                  <p className="typography-heading-md">CLAIM A PI.FYI USERNAME.</p>
                  <p className="typography-body-sm opacity-60">
                    Personalize your page or keep it anonymous with{' '}
                    <span className="text-brand-highlight">@{auth.user?.username}</span> (auto
                    generated)
                  </p>
                </div>

                <div className="flex flex-col gap-[6px]">
                  <label className="typography-body-sm text-brand-lightgrey">Username</label>
                  <PerfInput
                    placeholder={`You're currently @${auth.user?.username}`}
                    {...register('username')}
                    error={errors.username}
                  />
                </div>
                {/* <PerfInput
                  placeholder="Password"
                  type="password"
                  {...register('password')}
                  error={errors.password}
                />
                <PerfInput
                  placeholder="Confirm password"
                  type="password"
                  {...register('confirmPassword')}
                  error={errors.confirmPassword}
                /> */}

                <div className="flex flex-col gap-[6px]">
                  <label className="typography-body-sm text-brand-lightgrey">
                    Where did you hear about us?
                  </label>
                  <select
                    placeholder="Perfectly Imperfect Newsletter"
                    className="w-full h-10 pl-4 pr-4 has-[option:disabled:checked]:text-gray-400 rounded-none min-w-0 outline outline-2 outline-transparent outline-offset-2 relative appearance-none transition border border-main border-brand-main leading-none tracking-[1%] bg-inherit hover:border-brand-highlight focus:border-brand-highlight mb-1.5 font-normal typography-body-md text-[16px]"
                    {...register('discoverySource')}
                  >
                    <option value="" disabled selected>
                      Select option
                    </option>
                    {discoveryList.map((listItem) => (
                      <option key={listItem} value={listItem}>
                        {listItem}
                      </option>
                    ))}
                  </select>
                </div>

                <p className="typography-body-sm text-brand-lightgrey">
                  By clicking Finish, you agree to our{' '}
                  <TWLink to="/terms" className="text-brand-highlight hover:underline">
                    Terms
                  </TWLink>{' '}
                  and{' '}
                  <TWLink to="/terms" className="text-brand-highlight hover:underline">
                    Privacy
                  </TWLink>{' '}
                  conditions.
                </p>

                <div className="flex flex-col gap-3">
                  <div className="flex gap-3">
                    <TWButton variant="secondary" disabled={loading} onClick={handleClose}>
                      Skip
                    </TWButton>
                    <TWButton
                      type="submit"
                      disabled={loading}
                      className={loading ? 'opacity-50' : ''}
                    >
                      Finish
                    </TWButton>
                  </div>

                  <p className="typography-body-sm text-brand-lightgrey mt-3">
                    Already signed up?{' '}
                    <TWLink to="/sign-in" className="text-brand-highlight hover:underline">
                      Sign in
                    </TWLink>
                  </p>
                  <p className="typography-body-sm text-brand-lightgrey">
                    Confused?{' '}
                    <TWLink to="/what-is-this" className="text-brand-highlight hover:underline">
                      Learn more
                    </TWLink>
                  </p>
                </div>
              </div>
            </form>
          )}
        </div>
      </ModalContent>
    </Modal>
  );
}
