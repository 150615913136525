'use client';
import React from 'react';
import { Box, Heading, Stack, Text, useTheme } from '@chakra-ui/react';
import _ from 'lodash';
import GenericFeedInfiniteScrollView from 'shared/misc/components/by-type/feed/GenericFeedInfiniteScroll';
import { FeedItemType, RecCategory } from 'shared/__generated__/graphql';
import { getRecQueryCategoryAtString } from 'shared/data/rec';
import { useThemeColors } from 'shared/misc/hooks/useThemeColors';

export default function CategoryView({ category }: { category: RecCategory }) {
  const themeColors = useThemeColors();
  const strokeColor = themeColors.titleBorder;

  const title = getRecQueryCategoryAtString(category.toUpperCase() as RecCategory);

  return (
    <Box display="flex" justifyContent="center" p="24px" mb="100px">
      <Stack spacing="24px" width="100%" maxWidth="900px">
        <Stack spacing="24px" justifyContent="center">
          <Heading
            as="h1"
            textAlign="center"
            variant="XL"
            textTransform="uppercase"
            mb="24px"
            mt="48px"
            css={{
              WebkitTextStroke: '2px',
              WebkitTextStrokeColor: strokeColor,
            }}
          >
            {title ? title.toUpperCase() : 'NOT FOUND'}
          </Heading>
          {!title && (
            <Text textStyle="brand.headingSm" alignSelf="center">
              This category doesn't exist...
            </Text>
          )}
          {title && (
            <GenericFeedInfiniteScrollView
              hideAllFeedContext
              category={category}
              onlyFollowing={false}
              types={[
                FeedItemType.AddRecommendation,
                FeedItemType.NewPromptRecReply,
                FeedItemType.NewRePostWithContent,
                FeedItemType.NewPrompt,
              ]}
            />
          )}
        </Stack>
      </Stack>
    </Box>
  );
}
