

import { useRouter } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useAuth } from 'shared/misc/hooks/useAuth';
import useMutationWithAutoToast from 'shared/misc/hooks/useMutationWithAutoToast';
import { USER_SIGN_UP_STEP } from 'shared/data/user';
import useUpdateSetupStep from 'shared/misc/hooks/useUpdateSetupStep';
import { gql } from 'shared/__generated__/gql';

interface Props {
  code: string;
}

export default function AutoApplyInvite({ code }: Props) {
  const auth = useAuth();

  const router = useRouter();
  const { updateStep } = useUpdateSetupStep({ preload: [USER_SIGN_UP_STEP.MORE] });

  const [useInviteCode] = useMutationWithAutoToast(USE_INVITE_CODE, {
    successMessage: "Valid invite code. You're in!",
    errorMessage: 'Invalid code.',
  });

  useEffect(() => {
    if (auth.user) {
      const { signUpStep } = auth.user;
      if (!auth.user.invite && signUpStep) {
        useInviteCode({
          variables: { code },
          onCompleted: () => {
            if (signUpStep === USER_SIGN_UP_STEP.PREMIUM) {
              updateStep(null);
              router.navigate({ to: '/home' });
            } else {
              router.navigate({ to: '/home' });
            }
          },
        });
      } else {
        router.navigate({ to: '/home' });
      }
    } else {
      router.navigate({ to: `/welcome/${code}` });
    }
  }, []);

  return null;
}

const USE_INVITE_CODE = gql(/* GraphQL */ `
  mutation useInviteCode2($code: String!) {
    useInviteCode(code: $code) {
      id
      isPremium
      substackStatus
      invite {
        id
      }
    }
  }
`);
