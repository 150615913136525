import React from 'react';
import * as Sentry from '@sentry/react';
import Link from 'next/link';

export default function page({ error }: { error: Error }) {
  React.useEffect(() => {
    // Log the error to Sentry
    Sentry.captureException(error, {
      level: 'fatal',
      tags: {
        errorBoundary: true,
        error_message: error.message,
      },
    });

    const SESSION_STORAGE_KEY = `PI_ERROR_OCCURED_HAS_RECOVERED_${error.message}`;

    if (!sessionStorage.getItem(SESSION_STORAGE_KEY)) {
      sessionStorage.setItem(SESSION_STORAGE_KEY, 'true');
      window.location.reload();
    }
  }, [error]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', padding: '24px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            justifyContent: 'center',
          }}
        >
          <h1
            style={{
              textAlign: 'center',
              fontSize: '48px',
              marginTop: '48px',
              marginBottom: '0',
            }}
            onClick={() => {
              alert(error.message + ' ' + error.stack || 'No stack trace');
            }}
          >
            😢
          </h1>
          <h1
            style={{
              textAlign: 'center',
              fontSize: '48px',
              marginTop: '0',
              marginBottom: '12px',
            }}
          >
            ERROR
          </h1>
          <div>
            <p
              style={{
                textAlign: 'center',
                fontSize: '18px',
                fontWeight: 600,
              }}
            >
              It looks like something went (very) wrong.
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '16px' }}>
            <Link
              href="/home"
              style={{
                textDecoration: 'none',
                fontWeight: 600,
                textTransform: 'uppercase',
                fontSize: '16px',
              }}
            >
              Go Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
